
export const environment = {
  production: true,
  PORTAL_URI: `https://sales-crm.attardbros.com/`,
  API_ENDPOINT: 'https://sales-crm-system-api.attardbros.com/',
  API_GRAPHQL: `https://sales-crm-system-api.attardbros.com/api/graphql`,

  AUTHORITY_ENDPOINT: 'https://id.attardbros.com',
  POST_LOGOUT_REDIRECT_URI: 'https://sales-crm.attardbros.com/',
  REDIRECT_URI: 'https://sales-crm.attardbros.com/auth',
  SILENT_REDIRECT_URI: 'https://sales-crm.attardbros.com/silent',

  REAP_URI: 'https://units-portfolio.attardbros.com',

  CLIENT_ID: "crmsalesweb",
  SCOPE: "openid profile crmsalesapi.web role orchestrate.web",

  CENTRAL : `https://scenic-central.attardbros.com`,

};
